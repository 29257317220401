<template>
    <styled-interface hide-toolbar>
        <template #interface-heading>
            Reporting
        </template>

        <styled-card class="advertising-card">
            <template #heading>
                {{ currentPlatform.name }} Budgeting for {{ timing.now.format('MMMM, YYYY') }}
                <span class="days-remaining">
                    {{ currentMonthProgressDescription }}
                </span>
            </template>
            <template #action-buttons>
                <div
                    v-if="!loadingMetrics && hasConfirmedLoad"
                    class="grey--text">
                    <em>Updated
                        <time-ago :value="timing.now.toDate()" />
                    </em>
                </div>
                <styled-tooltip v-if="!loadingDealers && failedDealers.length">
                    <template #content>
                        <p>
                            We were unable to retrieve metrics for the following {{
                                accountSuffix(failedDealers.length)
                            }}. Please contact support if the
                            issue persists:
                        </p>
                        <ul>
                            <li
                                v-for="{ name, id } in failedDealers"
                                :key="id">
                                {{ name }}
                            </li>
                        </ul>
                    </template>
                    <a
                        href="#"
                        @click.prevent="() => null">
                        Error on {{ failedDealers.length }} {{ accountSuffix(failedDealers.length) }}
                    </a>
                </styled-tooltip>
                <action-button
                    icon="refresh"
                    :disabled="loadingMetrics || loadingDealers"
                    @click="handleRefresh">
                    refresh
                </action-button>
                <action-button
                    icon="download"
                    :disabled="loadingMetrics || currentDealerMetrics.length === 0"
                    @click="exportCSV">
                    Export CSV
                </action-button>
            </template>
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global :disabled="loadingDealers || loadingMetrics" />
                </div>
            </div>
            <div
                v-if="loadingDealers || groupsLoading"
                class="ma-5">
                <loader label="Loading accounts..." />
            </div>
            <div
                v-else-if="!hasConfirmedLoad && !loadingDealers"
                class="confirm-dialog">
                <h3>Are you ready to run the report?</h3>
                <p class="mb-4">
                    You are about to load budgeting data for
                    {{ activeDealers.length }} {{ accountSuffix(activeDealers.length) }}. This can
                    take a while, are you sure you want to continue?
                </p>
                <p>
                    <em>
                        Estimated time: {{ estimatedTime }}
                    </em>
                </p>
                <div class="text-xs-center mt-4">
                    <styled-button
                        @click="handleLoadConfirmation">
                        LOAD REPORT
                    </styled-button>
                </div>
            </div>
            <div
                v-if="hasConfirmedLoad && loadingMetrics"
                class="metrics-loader">
                <styled-progress-bar
                    :value="loadingPercent / 100" />
                <div class="text-xs-center mt-4">
                    {{ loadingMessage }}
                </div>
            </div>
            <styled-table
                v-else-if="hasConfirmedLoad"
                class="budgeting-table"
                :headers="headers"
                :items="currentDealerMetrics"
                :rows-per-page="50"
                min-width="1300px"
                :descending="false"
                has-search>
                <template #filters>
                    <div
                        v-if="isFacebookComponent"
                        class="table-field">
                        <v-select
                            v-model="currentCampaignFilter"
                            :items="campaignFilters"
                            item-text="label"
                            item-value="value"
                            label="Filter by"
                            class="styled-field styled-field-small"
                            flat
                            hide-details
                            small
                            dense
                            return-object
                            @change="loadData()" />
                    </div>
                    <div class="table-field">
                        <v-select
                            v-model="neededAdjustmentFilter"
                            :items="neededAdjustmentFilters"
                            label="Adjustment"
                            class="styled-field styled-field-small"
                            flat
                            hide-details
                            small
                            dense />
                    </div>
                    <div class="table-field">
                        <v-select
                            v-model="spendToDateFilter"
                            :items="spendToDateFilters"
                            label="Spend to date"
                            class="styled-field styled-field-small"
                            flat
                            hide-details
                            small
                            dense />
                    </div>
                </template>
                <template #items="{ item, index }">
                    <tr
                        :key="item.dealerId"
                        :class="[
                            (index % 2) ? 'row-even' : 'row-odd',
                            item.budgeting.suggestedDailyAdjustment.value > 1 ? 'budget-alert' : '',
                            item.budgeting.suggestedDailyAdjustment.value < -1 ? 'budget-alert' : '',
                            item.budgeting.suggestedDailyAdjustment.value > 5 ? 'budget-alert-high' : '',
                            item.budgeting.suggestedDailyAdjustment.value < -5 ? 'budget-alert-high' : '',
                            item.campaigns.length ? '' : 'no-campaigns'
                        ]">
                        <td>
                            <router-link
                                :to="{
                                    name: 'dealer',
                                    params: {
                                        dealer_id: item.dealerId
                                    }
                                }">
                                {{ item.dealerName }}
                            </router-link>
                        </td>
                        <td class="py-2">
                            <div class="layout align-center">
                                <a
                                    v-if="openAdAccount(item)"
                                    :href="openAdAccount(item)"
                                    target="_blank"
                                    class="mr-1">
                                    {{ item.adAccountExternal.name }}
                                </a>
                                <span v-else>
                                    {{ item.adAccountExternal.name }}
                                </span>
                                <styled-tooltip
                                    position="top"
                                    class="ml-auto action-icon">
                                    <template #content>
                                        View settings in Business Manager
                                    </template>
                                    <a
                                        target="_blank"
                                        :href="openAdAccountSettings(item) ?? '#'">
                                        <icon
                                            name="cog"
                                            size="15" />
                                    </a>
                                </styled-tooltip>
                                <styled-tooltip
                                    position="top"
                                    class="ml-1 action-icon">
                                    <template #content>
                                        View payment settings &amp; spend limits
                                    </template>
                                    <a
                                        target="_blank"
                                        :href="openAdAccountBilling(item) ?? '#'">
                                        <icon
                                            name="money-sack"
                                            size="15" />
                                    </a>
                                </styled-tooltip>
                            </div>
                        </td>
                        <td>
                            <budget-status
                                :value="item"
                                :platform="platform" />
                        </td>
                        <table-cell
                            :is-null="!item.budgeting.spendToDate.value"
                            class="text-xs-center">
                            ${{ item.budgeting.spendToDate.value }}
                        </table-cell>
                        <td class="text-xs-center">
                            <styled-progress-bar
                                v-if="item.budgeting.monthlyBudget?.value && item.budgeting.spendToDate.value"
                                height="10px"
                                :color="(item.budgeting.spendToDate.value >= item.budgeting.monthlyBudget.value) ? 'red' : 'blue'"
                                :value="item.budgeting.spendToDate.value / item.budgeting.monthlyBudget.value"
                                arrows />
                        </td>
                        <td class="text-xs-center">
                            <manage-ad-account-budget
                                :key="item.dealerId"
                                :dealer="item"
                                icon-size="15px"
                                :platform="platform"
                                @update="onEditBudget" />
                        </td>
                        <table-cell
                            :is-null="!item.budgeting.projectedSpend.value"
                            class="text-xs-center">
                            <projected-spend-tooltip
                                :value="item.budgeting"
                                :timing="timing" />
                        </table-cell>
                        <table-cell
                            :is-null="!item.budgeting.dailyBudget.value"
                            :class="{
                                'text-xs-center': true,
                                'budget-warning': !item.budgeting.monthlyBudget.value && item.budgeting.dailyBudget.value
                            }">
                            <styled-tooltip
                                v-if="!item.budgeting.monthlyBudget.value && item.budgeting.dailyBudget.value"
                                position="top"
                                :max-width="300">
                                {{ item.budgeting.dailyBudget.formatted }}
                                <template #content>
                                    This account is spending but a budget goal must be set for the calculations to work
                                </template>
                            </styled-tooltip>
                            <template v-else>
                                {{ item.budgeting.dailyBudget.formatted }}
                            </template>
                        </table-cell>
                        <table-cell
                            :is-null="!item.budgeting.averageDailyBudget.value"
                            class="text-xs-center">
                            {{ item.budgeting.averageDailyBudget.formatted }}
                        </table-cell>
                        <table-cell
                            :is-null="!item.budgeting.suggestedDailyBudget.value"
                            class="text-xs-center">
                            <suggested-daily-budget-tooltip
                                :value="item.budgeting"
                                :timing="timing" />
                        </table-cell>
                        <table-cell
                            :is-null="!item.budgeting.suggestedDailyAdjustment.value"
                            class="text-xs-center dark-column alert-column">
                            {{ item.budgeting.suggestedDailyAdjustment.formatted }}
                        </table-cell>
                    </tr>
                </template>
            </styled-table>
        </styled-card>
    </styled-interface>
</template>

<script>
import Loader from '@/components/globals/Loader';
import StyledButton from '@/components/globals/StyledButton';
import TimeAgo from '@/components/globals/TimeAgo';
import ManageAdAccountBudget from '@/components/globals/ManageAdAccountBudget';
import SuggestedDailyBudgetTooltip from '@/components/globals/budgeting/SuggestedDailyBudgetTooltip';
import ProjectedSpendTooltip from '@/components/globals/budgeting/ProjectedSpendTooltip';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import StyledProgressBar from '@/components/globals/StyledProgressBar';
import StyledCard from '@/components/globals/StyledCard';
import Icon from '@/components/globals/Icon';
import StyledTable from '@/components/globals/StyledTable';
import TableCell from '@/components/globals/TableCell';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';
import getPlatformBudgeting from '@/apis/global/budgeting/getPlatformBudgeting';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';
import sleep from '@/helpers/sleep';
import {
    getPlatformAdAccountBillingUrl,
    getPlatformAdAccountSettingsUrl,
    getPlatformAdAccountUrl,
} from '@/helpers/platformUrls';
import { ExportToCsv } from 'export-to-csv';
import { mapState } from 'vuex';
import chunk from 'lodash/chunk';
import calculateBudgeting, { calculateTiming } from '@/apis/global/budgeting/calculateBudgeting';
import BudgetStatus from './BudgetStatus';
import {
    FACEBOOK_BB_PLATFORM_ID,
    PLATFORM_ID_FACEBOOK,
    PLATFORM_ID_PINTEREST,
    PLATFORM_ID_SNAPCHAT,
    PLATFORM_ID_TIKTOK,
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_ADVERTISING_LITE,
} from '@/helpers/globals';


// SPEND TO DATE FILTERS
const ALL_ACCOUNTS = 'all';
const SPEND_ACCOUNTS = 'spend';
const NO_SPEND_ACCOUNTS = 'no-spend';

const PLATFORMS = {
    facebook: {
        id: PLATFORM_ID_FACEBOOK,
        name: 'Facebook',
        batchSize: 25,
        estimatedTimePer: 1000,
    },
    tiktok: {
        id: PLATFORM_ID_TIKTOK,
        name: 'TikTok',
        // TikTok needs to be lower due to their rate limiting
        batchSize: 3,
        estimatedTimePer: 2000,
    },
    snapchat: {
        id: PLATFORM_ID_SNAPCHAT,
        name: 'Snapchat',
        batchSize: 8,
        estimatedTimePer: 2500,
    },
    pinterest: {
        id: PLATFORM_ID_PINTEREST,
        name: 'Pinterest',
        batchSize: 15,
        estimatedTimePer: 1000,
    },
};

export default {
    components: {
        StyledButton,
        Loader,
        TimeAgo,
        BudgetStatus,
        ManageAdAccountBudget,
        SuggestedDailyBudgetTooltip,
        ProjectedSpendTooltip,
        GroupsPickerGlobal,
        StyledTooltip,
        StyledInterface,
        StyledCard,
        ActionButton,
        StyledProgressBar,
        StyledTable,
        Icon,
        TableCell,
    },
    props: {
        platform: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            noGroups: false,
            timing: null,
            hasConfirmedLoad: false,
            neededAdjustmentFilter: null,
            spendToDateFilter: ALL_ACCOUNTS,
            dealerMetrics: [],
            activeDealers: [],
            facebookProductIds: [PRODUCT_ADVERTISING, PRODUCT_ADVERTISING_PLUS, PRODUCT_ADVERTISING_LITE],
            failedDealers: [],
            loadingMetrics: false,
            loadingDealers: false,
            loadingMessage: '',
            loadingPercent: 0,
            currentCampaignFilter: { label: 'All Campaigns', value: 'all', filter: [] },
            campaignFilters: [
                { label: 'All Campaigns', value: 'all', filter: [] },
                {
                    label: 'Buyerbridge Campaigns',
                    value: 'buyerbridge',
                    filter: [{
                        field: 'adlabels',
                        operator: 'ANY',
                        value: ['buyerbridge'],
                    }],
                },
            ],
            neededAdjustmentFilters: [
                {
                    text: 'All Values',
                    value: null,
                },
                {
                    text: 'Over $1',
                    value: 1,
                },
                {
                    text: 'Over $5',
                    value: 5,
                },
                {
                    text: 'Over $20',
                    value: 20,
                },
            ],
            spendToDateFilters: [
                {
                    text: 'All accounts',
                    value: ALL_ACCOUNTS,
                },
                {
                    text: 'Spend accounts',
                    value: SPEND_ACCOUNTS,
                },
                {
                    text: 'No spend accounts',
                    value: NO_SPEND_ACCOUNTS,
                },
            ],
            showDealersWithNoSpend: false,
            headers: [
                {
                    text: 'Account Name',
                    value: 'dealerName',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                },
                {
                    text: 'Ad Account Name',
                    value: 'adAccountExternal.name',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    tooltip: 'The name of the ad account within the platform.',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    tooltip: 'The current status of the budgeting tool for this ad account.',
                },
                {
                    text: 'Spend to Date',
                    value: 'budgeting.spendToDate.value',
                    align: 'left',
                    width: '8%',
                    sortable: true,
                    tooltip: 'The amount of money spent to date across your daily budgeted campaigns.',
                },
                {
                    text: '',
                    value: '',
                    align: 'left',
                    width: '8%',
                    sortable: false,
                },
                {
                    text: 'Monthly Budget Goal',
                    value: 'budgeting.monthlyBudget.value',
                    align: 'left',
                    width: '8%',
                    sortable: true,
                    tooltip: 'The currently configured monthly budget goal in BuyerBridge for your daily budgeted campaigns. This can be edited in the cells below.',
                },
                {
                    text: 'Projected Spend',
                    value: 'budgeting.projectedSpend.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: 'The projected spend for the month across the selected campaigns calculated as the average daily budget to date multiplied by the days in the current month',
                },

                {
                    text: 'Current Daily Budget',
                    value: 'budgeting.dailyBudget.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: 'The sum of all <em>active</em> daily budgets across the selected campaigns',
                },
                {
                    text: 'Average Daily Budget',
                    value: 'budgeting.averageDailyBudget.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: 'The average amount of spend per day to date',
                },
                {
                    text: 'Suggested Daily Budget',
                    value: 'budgeting.suggestedDailyBudget.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: 'The suggested new daily budget to set across the selected campaigns calculated as: (Monthly Budget Goal - Current Month\'s Spend) / Remaining Time in the Month',
                },
                {
                    text: 'Suggested Daily Adjustment',
                    value: 'budgeting.suggestedDailyAdjustment.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: 'The suggested daily budget adjustment to make across the selected campaigns calculated as the Adjusted Daily Budget minus the Daily Budget to Date',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dateRange: (state) => state.metrics.dateRange,
            currentAgencyId: (state) => state.agency.currentAgency.id,
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
        }),
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        },
        isFacebookComponent() {
            return this.currentPlatform.id === FACEBOOK_BB_PLATFORM_ID;
        },
        currentPlatform() {
            return PLATFORMS[this.platform];
        },
        dealersToBeShown() {
            switch (this.spendToDateFilter) {
                case ALL_ACCOUNTS:
                    return this.dealerMetrics;
                case SPEND_ACCOUNTS:
                    return this.dealersWithSpend;
                case NO_SPEND_ACCOUNTS:
                    return this.dealersWithNoSpend;
                default:
                    return this.dealerMetrics;
            }
        },
        currentDealerMetrics() {
            if (this.neededAdjustmentFilter == null) {
                return this.dealersToBeShown;
            }

            return this.dealersToBeShown.filter(item => {
                // Only show items with suggestions
                if (item.budgeting.suggestedDailyAdjustment.value == null) {
                    return false;
                }

                return (
                    item.budgeting.suggestedDailyAdjustment.value > this.neededAdjustmentFilter ||
                    item.budgeting.suggestedDailyAdjustment.value < -this.neededAdjustmentFilter
                );
            });
        },
        currentMonthProgressDescription() {
            return `${this.timing.daysPassedInMonth} day${this.timing.daysPassedInMonth === 1 ? '' : 's'} passed & ${this.timing.daysRemainingInMonth} day${this.timing.daysRemainingInMonth === 1 ? '' : 's'} remaining`;
        },
        estimatedTime() {
            const { estimatedTimePer } = this.currentPlatform;

            const count = this.activeDealers.length;
            const time = (estimatedTimePer * count) / 1000;

            // does the same job as parseInt truncates the float
            const minutes = (time / 60) | 0;
            const seconds = (time % 60) | 0;

            const parts = [];

            if (minutes > 0) {
                parts.push(`${minutes} minutes`);
            }

            if (seconds > 0) {
                parts.push(`${seconds} seconds`);
            }

            return parts.join(' and ');
        },
        dealersWithSpend() {
            return this.dealerMetrics.filter(dealer => dealer.hasSpend);
        },
        dealersWithNoSpend() {
            return this.dealerMetrics.filter(dealer => !dealer.hasSpend);
        },
    },
    watch: {
        selectedGroups() {
            this.checkGroupsAndLoad();
        },
    },
    created() {
        this.timing = calculateTiming();
        this.checkGroupsAndLoad();
    },
    mounted() {
        this.setTitle();
    },
    methods: {
        checkGroupsAndLoad() {
            if (this.selectedGroups.length && !this.selectedGroups.includes(null)) {
                this.noGroups = false;
            } else {
                this.noGroups = true;
            }
            this.reset();
            this.getActiveDealers();
        },
        accountSuffix(metricsLength) {
            if (metricsLength === 1) {
                return 'account';
            }

            return 'accounts';
        },
        reset() {
            this.hasConfirmedLoad = false;
            this.activeDealers = [];
            this.dealerMetrics = [];
            this.failedDealers = [];
        },
        handleRefresh() {
            if (this.hasConfirmedLoad) {
                this.loadData();
            } else {
                this.getActiveDealers();
            }
        },
        setTitle() {
            this.$title = `${this.currentPlatform.name} Budgeting - ${this.agency.name}`;
        },
        openAdAccount(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        },
        openAdAccountSettings(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountSettingsUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        },
        openAdAccountBilling(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountBillingUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        },
        handleLoadConfirmation() {
            this.hasConfirmedLoad = true;
            this.loadData();
        },
        async loadData() {
            this.loadingMetrics = true;
            this.failedDealers = [];
            this.loadingPercent = 0;

            await this.getAllDealerMetrics();

            // Recalculate timing
            this.timing = calculateTiming();

            this.loadingMessage = 'Finished loading. Preparing report!';

            await sleep(1000);

            this.loadingMetrics = false;
            this.loadingMessage = '';
            this.loadingPercent = 0;
        },
        async getAllDealerMetrics() {
            const chunks = chunk(this.activeDealers, this.currentPlatform.batchSize);

            let allData = [];

            for (let i = 0; i < chunks.length; i++) {

                this.loadingMessage = `Loading accounts: ${Math.round(this.loadingPercent)}%`;

                const data = await Promise.all(chunks[i].map(this.getDealerMetric));
                allData = allData.concat(data);

                // Increment the loader
                this.loadingPercent += (100 / chunks.length);
            }

            this.dealerMetrics = allData.filter(item => item !== null).map(Object.freeze);
        },
        getDealerAdAccount(dealer) {
            if (this.isFacebookComponent) {
                const adAccountId = dealer.facebook_ad_account_annotations.data?.[0]?.facebook_ad_account_id.replace('act_', '');

                return {
                    external_ad_account_id: adAccountId,
                    // Used to match the ad account to the dealer when updating the budget on onEditBudget
                    id: adAccountId,
                };
            }

            return dealer.ad_accounts.data.find(adAccount => {
                return this.currentPlatform.id === adAccount.platform_id;
            });
        },
        getMonthlyBudget(dealer, adAccount) {
            if (this.isFacebookComponent) {
                return dealer?.budget;
            }

            return adAccount.monthly_budget;
        },
        async getDealerPlatformAdAccount(dealerId, adAccountId) {
            if (this.isFacebookComponent) {
                return this.$apiRepository.facebookGraphApiPost({
                    dealerId,
                    node: 'ad-account',
                    payload: {
                        fields: ['name'],
                    },
                });
            }

            return this.$apiRepository.getDealerPlatformAdAccount({
                dealerId,
                platform: this.platform,
                adAccountId,
            });
        },
        getBusinessManager(dealer) {
            if (this.isFacebookComponent) {
                return {
                    external_business_manager_id: this.agency.facebook_business_id,
                };
            }

            return dealer.agency.business_managers.data.find(businessManager => {
                return this.currentPlatform.id === businessManager.platform_id;
            });
        },
        async getDealerMetric(dealer) {
            try {
                const adAccount = this.getDealerAdAccount(dealer);
                const monthlyBudget = this.getMonthlyBudget(dealer, adAccount);
                const businessManager = this.getBusinessManager(dealer);
                const adAccountExternal = await this.getDealerPlatformAdAccount(dealer.id, adAccount.external_ad_account_id);

                const budgetingData = await getPlatformBudgeting({
                    dealerId: dealer.id,
                    platform: this.platform,
                    adAccountId: adAccount.facebook_ad_account_id,
                    monthlyBudget,
                    filter: this.currentCampaignFilter.filter,
                });

                return {
                    // Trim to critical data
                    dealerName: dealer.name,
                    dealerId: dealer.id,
                    adAccount,
                    businessManager,
                    adAccountExternal,
                    ...budgetingData, // { campaigns, budgeting }
                };
            } catch (error) {
                // Track failures
                this.failedDealers.push(dealer);

                // Filter out downstream
                return null;
            }
        },
        async getActiveDealers() {
            try {

                this.loadingDealers = true;

                let dealers = [];

                const request = async(pageNumber) => {
                    const response = await this.$apiRepository.getAgencyDealersWithPlatform({
                        selectedGroups: this.selectedGroupsIds,
                        agencyId: this.currentAgencyId,
                        platformId: this.currentPlatform.id,
                        pageNumber,
                    });

                    // Non facebook dealers get filtered on the call above, we need to filter fb here as they use different set of annotations
                    dealers = dealers.concat(response.data.data.filter(dealer => {
                        // TEMP FIX til we can got back and fix Ad Set loading speed
                        if (dealer.id === 10061) {
                            return false;
                        }

                        if (this.currentPlatform.id === PLATFORM_ID_FACEBOOK) {
                            return Boolean(dealer?.facebook_ad_account_annotations?.data?.length)
                                && dealer.products.data.some(product => this.facebookProductIds.includes(product.id));
                        }

                        return true;
                    }));

                    if (response.data.meta.current_page !== response.data.meta.last_page) {
                        await request(pageNumber + 1);
                    }
                };

                await request(1); // Kick off first page request

                this.activeDealers = dealers.map(Object.freeze);
            } catch (error) {
                this.$flash('Error retrieving dealers, contact the support if the error persists.', 'red');
            } finally {
                this.loadingDealers = false;
            }

        },
        onEditBudget({ budget, adAccount }) {
            const index = this.dealerMetrics.findIndex(item => {
                return (item.adAccount.id === adAccount.id);
            });

            // Get the insights to be updated and set the budget
            const newData = { ...this.dealerMetrics[index] };
            newData.adAccount.monthly_budget = budget;

            newData.budgeting = calculateBudgeting({
                monthlyBudget: parseFloat(budget),
                spendToDate: newData.budgeting.spendToDate.value,
                dailyBudget: newData.budgeting.dailyBudget.value,
            });

            this.$set(
                this.dealerMetrics,
                index,
                newData,
            );
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `${this.platform}_budgeting_${new Date().getTime()}`,
            };
            const csvExporter = new ExportToCsv(options);

            const result = this.dealerMetrics.map(item => {
                return {
                    dealer: item.dealerName,
                    ad_account: item.adAccountExternal.name,
                    status: !item.adAccount.monthly_budget ? 'No Budget' : item.campaigns.length ? item.campaigns.length + ' campaigns' : 'No campaigns',
                    monthly_budget_goal: item.budgeting?.monthlyBudget.formatted || '',
                    projected_spend: item.budgeting?.projectedSpend.formatted || '',
                    spend_to_date: item.budgeting?.spendToDate.formatted || '',
                    current_daily_budget: item.budgeting?.dailyBudget.formatted || '',
                    average_daily_budget: item.budgeting?.averageDailyBudget.formatted || '',
                    suggested_daily_budget: item.budgeting?.suggestedDailyBudget.formatted || '',
                    suggested_daily_adjustment: item.budgeting?.suggestedDailyAdjustment.formatted || '',
                };
            });

            csvExporter.generateCsv(result);
        },
    },
};
</script>

<style lang="scss" scoped>
.advertising-card {
    overflow: visible;
}

.metrics-loader {
    padding: 80px 60px;
}

.days-remaining {
    color: $gray;
    font-size: 1rem;
    margin-left: 10px;
    font-style: italic;
}

.confirm-dialog {
    margin: 60px auto;
    padding: 20px;
    border: 1px solid $gray-light;
    background-color: $alabaster;
    max-width: 600px;
    @media (max-width: $bp-md) {
        margin: 60px 40px;
    }
}
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 30px;
    &__holder {
        max-width: 255px;
    }
}
</style>
