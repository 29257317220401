import { render, staticRenderFns } from "./BudgetStatus.vue?vue&type=template&id=60323960&scoped=true&"
import script from "./BudgetStatus.vue?vue&type=script&lang=js&"
export * from "./BudgetStatus.vue?vue&type=script&lang=js&"
import style0 from "./BudgetStatus.vue?vue&type=style&index=0&id=60323960&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60323960",
  null
  
)

export default component.exports